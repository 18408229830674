import type { IExportConfig, ExportFormat } from 'o365.modules.exportData.ts';
import type { ItemModel } from 'o365.modules.DataObject.types.ts';

import DataObject from 'o365.modules.DataObject.ts';
import { dataObjectExportData } from 'o365.modules.exportData.ts';

declare module "o365.modules.DataObject.ts" {
    interface DataObject<T> {
        /**
         * Export Data extension for 
         * exporting data from DataObjects 
         */
        export: ExportData<T>;
        exportData: ExportData<T>['exportData']
    }
}

Object.defineProperty(DataObject.prototype, 'export', {
    get() {
        if (this._export == null) {
            this._export = new ExportData(this);
        }
        return this._export;
    }
});
Object.defineProperty(DataObject.prototype, 'exportData', {
    get() {
        return this.export.exportData.bind(this.export);
    },
});


export default class ExportData<T extends ItemModel = ItemModel>{
    private _dataObject: DataObject<T>;

    private _defaultSheetName?: string;
    private _exportFileName?: string;
    private _exportDate?: Date;
  
    private _isLoading = false;
    private _exportPromise?: Promise<void>;
    private _exportPromiseResolve?: () => void;

    get isLoading() { return this._isLoading; }
    set isLoading(pValue) {
        if (!pValue && this._exportPromiseResolve) {
            this._exportPromiseResolve();
            this._exportPromiseResolve = undefined;
            this._exportPromise = undefined;
        } else if (pValue && this._exportPromise == null) {
            this._exportPromiseResolve = () => {};
            this._exportPromise = new Promise<void>(res => { this._exportPromiseResolve = res});
        }
        this._isLoading = pValue;
    }

    get exportPromise() { return this._exportPromise; }

    previousColumns?: any[];

    localizeDateTime:boolean = false;

    constructor(pDataObject: DataObject<T>) {
        this._dataObject = pDataObject;
        this._exportFileName = pDataObject.id;
    }

    /** Sets the default sheet name for this dataobject export operations */
    setDefaultSheetName(value?: string) {
        this._defaultSheetName = value;
    }
    /** Override exported file name (without extension) */
    setFileName(value?: string) {
        this._exportFileName = value;
    }
    /** Set exported date time to show in title row */
    setExportDate(value?: Date) {
        this._exportDate = value;
    }
    /** Get the default sheet name for this DataObject export */
    getDefaultSheetName() {
        return this._defaultSheetName;
    }
    /** Get filename for export */
    getFileName() {
        return this._exportFileName;
    }
    /** Get the exported date time shown in title row  */
    getExportDate() {
        return this._exportDate;
    }
    /** Export data from this DataObject */
    async exportData(pExportConfig: IExportConfig, pRequestData: any, pFormat: ExportFormat): Promise<void> {
        this.isLoading = true;

        try {
            pExportConfig.localizeDateTime = this.localizeDateTime;
            await dataObjectExportData(this._dataObject, pExportConfig, pRequestData, pFormat);
        } 
        finally {
            this.isLoading = false;
        }
    }
}